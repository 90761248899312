<template>
  <b-overlay :show="show" opacity="0.5" rounded="sm">
    <div>
      <employee-list-add-new
        v-model="isAddNewUserSidebarActive"
        :is-employee-handler-sidebar-active.sync="isAddNewUserSidebarActive"
        :employee="employee"
        :clear-employee-data="clearEmployeeData"
        @add-employee="addEmployee"
        :role-options="roleOptions"
        @refetch-data="refetchData"
      />

      <!-- Table Container Card -->
      <b-card no-body class="mb-0">
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="
                d-flex
                align-items-center
                justify-content-start
                mb-1 mb-md-0
              "
            >
              <label>{{ $t("entries") }}</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />

              <b-button variant="primary" @click="onExport()">
                {{ $t("export") }}
              </b-button>
            </b-col>

            <!-- Search -->
            <b-col cols="12" md="6">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  :placeholder="$t('Search employee...')"
                />
                <b-button
                  variant="primary"
                  @click="isAddNewUserSidebarActive = true"
                >
                  <span class="text-nowrap">Neuer Mitarbeiter</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          ref="refEmployeeListTable"
          class="position-relative"
          :items="fetchEmployees"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(employee)="data">
            <b-media vertical-align="center">
              <b-link
                :to="{ name: 'employees-edit', params: { id: data.item.id } }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.vorname }} {{ data.item.nachname }}
              </b-link>
            </b-media>
          </template>

          <!-- Column: Role -->
          <template #cell(role)="data">
            <div class="text-nowrap">
              <span class="align-text-top text-capitalize">{{
                data.item.userrole
              }}</span>
            </div>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="resetPassword(data.item.id)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{
                  $t("Reset password")
                }}</span>
              </b-dropdown-item>

              <b-dropdown-item
                :to="{ name: 'employees-edit', params: { id: data.item.id } }"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ $t("Edit") }}</span>
              </b-dropdown-item>

              <b-dropdown-item @click="showMsgDeleteConfirm(data.item.id)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ $t("Delete") }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              "
            >
              <span class="text-muted"
                >{{ $t("Showing") }} {{ dataMeta.from }} {{ $t("to") }}
                {{ dataMeta.to }} {{ $t("of") }} {{ dataMeta.of }} entries</span
              >
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-end
              "
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalEmployees"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BOverlay,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref, onUnmounted } from "@vue/composition-api";
import store from "@/store";
import { useToast } from "vue-toastification/composition";
import useEmployeesList from "./useEmployeesList";
import employeeStoreModule from "../employeeStoreModule";
import EmployeeListAddNew from "./EmployeeListAddNew.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import i18n from "@/libs/i18n";
import axios from "@axios"

export default {
  components: {
    EmployeeListAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ToastificationContent,
    BOverlay,
  },
  data() {
    return {
      show: false,
    };
  },
  setup() {
    const $toast = useToast();
    var vm = this;

    const STAFF_STORE_MODULE_NAME = "app-employee";

    // Register module
    if (!store.hasModule(STAFF_STORE_MODULE_NAME))
      store.registerModule(STAFF_STORE_MODULE_NAME, employeeStoreModule);

    // Unregister on leave
    onUnmounted(() => {
      if (store.hasModule(STAFF_STORE_MODULE_NAME))
        store.unregisterModule(STAFF_STORE_MODULE_NAME);
    });

    const blankEmployee = {
      id: null,
      vorname: "",
      nachname: "",
      emailadresse: "",
      gender: "Mann",
      userrole: "employee",
    };
    const employee = ref(JSON.parse(JSON.stringify(blankEmployee)));
    const clearEmployeeData = () => {
      employee.value = JSON.parse(JSON.stringify(blankEmployee));
    };

    const isAddNewUserSidebarActive = ref(false);

    const roleOptions = [
      { label: "admin", value: "admin" },
      { label: "employee", value: "employee" },
    ];

    const {
      fetchEmployees,
      tableColumns,
      perPage,
      currentPage,
      totalEmployees,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refEmployeeListTable,
      refetchData,
    } = useEmployeesList();

    return {
      // Sidebar
      isAddNewUserSidebarActive,
      employee,
      clearEmployeeData,
      fetchEmployees,
      tableColumns,
      perPage,
      currentPage,
      totalEmployees,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refEmployeeListTable,
      refetchData,

      roleOptions,
    };
  },
  methods: {
    onExport() {
      var vm = this;

      axios({
        url: `/mitarbeiter/export`,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        const [, filename]=response.headers["content-disposition"].split('filename=');
        fileLink.setAttribute("download", filename);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    addEmployee(val) {
      this.show = !this.show;
      store.dispatch("app-employee/addEmployee", val).then(() => {
        // eslint-disable-next-line no-use-before-define
        this.$toast({
          component: ToastificationContent,
          props: {
            title: i18n.t("Added"),
            icon: "AddIcon",
            variant: "success",
          },
        });
        this.refetchData();
        this.show = !this.show;
        this.isAddNewUserSidebarActive = false;
      });
    },
    removeEmployee() {
      this.show = !this.show;
      store
        .dispatch("app-employee/removeEmployee", { id: this.employee.value.id })
        .then(() => {
          // eslint-disable-next-line no-use-before-define
          this.$toast({
            component: ToastificationContent,
            props: {
              title: i18n.t("Deleted"),
              icon: "TrashIcon",
              variant: "success",
            },
          });
          this.refetchData();
          this.show = !this.show;
        });
    },
    updateEmployee(employeeData) {
      this.show = !this.show;
      store
        .dispatch("app-employee/updateEmployee", this.employeeData)
        .then(() => {
          // eslint-disable-next-line no-use-before-define
          this.$toast({
            component: ToastificationContent,
            props: {
              title: i18n.t("Updated"),
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.refetchData();
          this.show = !this.show;
        });
    },
    resetPassword(employeeId) {
      this.show = !this.show;
      store
        .dispatch("app-employee/resetPassword", { id: employeeId })
        .then(() => {
          this.show = !this.show;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: i18n.t("Reset password email sent"),
              icon: "EditIcon",
              variant: "success",
            },
          });
        });
    },
    showMsgDeleteConfirm(employeeId) {
      var vm = this;

      this.$bvModal
        .msgBoxConfirm(
          i18n.t("Are you sure you want to delete this employee?"),
          {
            title: i18n.t("Confirm"),
            size: "sm",
            okVariant: "primary",
            okTitle: i18n.t("Yes"),
            cancelTitle: i18n.t("No"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            store
              .dispatch("app-employee/removeEmployee", { id: employeeId })
              .then(() => {
                vm.refetchData();

                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: i18n.t("Deleted"),
                    icon: "TrashIcon",
                    variant: "success",
                  },
                });
              });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
